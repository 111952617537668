.login.login-1 .login-aside {
  max-width: 50%;
}
.side__bar {
  height: auto !important;
}
.react-datepicker-wrapper {
  width: 100%;
}
.summery-doc-view-form {
  height: 580px !important;
  background-color: #e4e4e4;
  box-shadow: 4px 5px 16px #e4e4e4;
  overflow: auto;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.summery-doc-view-form > div {
  padding: 20px 0px 20px 20px;
}
/* width */
.summery-doc-view-form > div::-webkit-scrollbar {
  width: 10px;
}
/* Track */
.summery-doc-view-form > div::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
.summery-doc-view-form > div::-webkit-scrollbar-thumb {
  background: #888;
}
/* Handle on hover */
.summery-doc-view-form > div::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.half__width {
  width: 50%;
}
.stepper_root {
  width: 100%;
}
/* .stepper_root button {
  margin-right: 10px;
} */
.stepper_root .instruction {
  margin-top: 8px;
  margin-bottom: 8px;
}

.custom__title_align_up {
  margin-top: -73px;
  margin-bottom: 20px;
  /* position: relative; */
  z-index: -1;
}
.dz_img_wrp > div {
  word-break: break-all;
}

.employee-company-table .MuiPaper-root.overflow-auto {
  overflow-x: unset !important;
  overflow-y: unset !important;
}

.reset_btn .svg-icon svg {
  height: 1.85rem !important;
  width: 1.25rem !important;
}

/* form {
  autocomplete: "off";
} */

@media (max-width: 991px) {
  .login.login-1 .login-aside {
    max-width: 100%;
    min-height: 40vh;
  }
  .login .mt-30 {
    margin-top: 10px !important;
  }

  .custom__title_align_up {
    margin-top: -42px;
    margin-bottom: 5px;
  }
  .custom__title_align_up .btn {
    padding: 6px 10px;
    font-size: 12px;
    font-weight: 400;
  }

  .aside-secondary-enabled #kt_aside,
  .aside-secondary-enabled #kt_aside > .aside-primary {
    width: 90px;
    background-color: #fff;
  }
}

@media (max-width: 480px) {
  .custom__stepper .MuiPaper-root {
    flex-wrap: wrap;
    padding: 15px;
  }
  .custom__stepper .MuiPaper-root .MuiStepConnector-root {
    display: none;
  }
  .custom__stepper .MuiPaper-root > div {
    width: 50%;
    margin-bottom: 10px;
  }
  .react-datepicker__input-container {
    display: block;
    width: 100%;
  }
  .btn.btn-primary {
    color: #ffffff;
    background-color: #3e7abd;
    border-color: #3e7abd;
  }
  .summery-doc-view-form {
    height: 650px !important;
  }
  .custom__title_action .text-right {
    text-align: left !important;
    margin-top: 20px;
  }
  .half__width {
    width: 100%;
  }
  .custom__title_align_up {
    margin-top: -10px;
  }
  .custom__title_align_up .text-right {
    text-align: right !important;
  }
  .tooltip {
    display: none;
  }
}
